import React, { useEffect } from "react";
import Go from "./../../../assets/images/blue_go.svg";
import OurWorkJson from "./../../../Data/OurWork.json";
import { Link, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

export default function WorkBox() {
    let { id } = useParams();
    useEffect(() => {
        AOS.init({
            duration: 1500,
        });
    }, []);
    return (
        <section className="work_container">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title" data-aos="fade-up">
                            A Showcase of Our Company’s Creative Work
                        </div>
                        {/* our works filter  */}
                        {/* <div className="apps_tabs" data-aos="fade-up" data-aos-delay={150}>
                            <button className='all active'>
                                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.1546 2.6665H7.6213C4.7013 2.6665 3.16797 4.19984 3.16797 7.1065V9.63984C3.16797 12.5465 4.7013 14.0798 7.60797 14.0798H10.1413C13.048 14.0798 14.5813 12.5465 14.5813 9.63984V7.1065C14.5946 4.19984 13.0613 2.6665 10.1546 2.6665Z" fill="#65B135"/>
                                    <path d="M25.3952 2.6665H22.8619C19.9552 2.6665 18.4219 4.19984 18.4219 7.1065V9.63984C18.4219 12.5465 19.9552 14.0798 22.8619 14.0798H25.3952C28.3019 14.0798 29.8352 12.5465 29.8352 9.63984V7.1065C29.8352 4.19984 28.3019 2.6665 25.3952 2.6665Z" fill="#65B135"/>
                                    <path d="M25.3952 17.9067H22.8619C19.9552 17.9067 18.4219 19.4401 18.4219 22.3467V24.8801C18.4219 27.7867 19.9552 29.3201 22.8619 29.3201H25.3952C28.3019 29.3201 29.8352 27.7867 29.8352 24.8801V22.3467C29.8352 19.4401 28.3019 17.9067 25.3952 17.9067Z" fill="#65B135"/>
                                    <path d="M10.1546 17.9067H7.6213C4.7013 17.9067 3.16797 19.4401 3.16797 22.3467V24.8801C3.16797 27.8001 4.7013 29.3334 7.60797 29.3334H10.1413C13.048 29.3334 14.5813 27.8001 14.5813 24.8934V22.3601C14.5946 19.4401 13.0613 17.9067 10.1546 17.9067Z" fill="#65B135"/>
                                </svg>
                                <span>All</span>
                            </button>
                            <button className="web">
                                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.9867 13.5066C27.5333 13.3199 26.9333 13.2266 26.1867 13.2266H19.1467C16.8267 13.2266 16 14.0532 16 16.3999V26.7199C16 27.4932 16.0933 28.0932 16.2933 28.5599C16.7067 29.5199 17.5867 29.8932 19.1467 29.8932H26.1867C28.5067 29.8932 29.3333 29.0532 29.3333 26.7199V16.3999C29.3333 14.8132 28.96 13.9199 27.9867 13.5066ZM24 26.8932H21.3333C21.3067 26.8932 21.2667 26.8932 21.24 26.8799C21.04 26.8666 20.8667 26.7999 20.72 26.6666C20.48 26.4932 20.3333 26.2132 20.3333 25.8932C20.3333 25.3466 20.7867 24.8932 21.3333 24.8932H24C24.5467 24.8932 25 25.3466 25 25.8932C25 26.4399 24.5467 26.8932 24 26.8932Z" fill="#3769D2"/>
                                    <path d="M27.9864 8.81323V9.89323C27.9864 10.6266 27.3864 11.2266 26.653 11.2266H19.1464C15.733 11.2266 13.9997 12.9732 13.9997 16.3999V28.5599C13.9997 29.2932 13.3997 29.8932 12.6664 29.8932H10.0664C9.53302 29.8932 9.10635 29.4666 9.10635 28.9466C9.10635 28.4132 9.53302 27.9999 10.0664 27.9999H12.6664V22.8932H7.99969C5.03969 22.7466 2.67969 20.3066 2.67969 17.3066V8.81323C2.67969 5.73323 5.18635 3.22656 8.27969 3.22656H22.3997C25.4797 3.22656 27.9864 5.73323 27.9864 8.81323Z" fill="#3769D2"/>
                                </svg>
                                <span>Web Apps</span>
                            </button>
                            <button className='mobile'>
                                <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.652 0.666992H5.34537C1.66536 0.666992 0.332031 2.00033 0.332031 5.74699V22.2537C0.332031 26.0003 1.66536 27.3337 5.34537 27.3337H16.6387C20.332 27.3337 21.6654 26.0003 21.6654 22.2537V5.74699C21.6654 2.00033 20.332 0.666992 16.652 0.666992ZM10.9987 23.7337C9.7187 23.7337 8.66537 22.6803 8.66537 21.4003C8.66537 20.1203 9.7187 19.067 10.9987 19.067C12.2787 19.067 13.332 20.1203 13.332 21.4003C13.332 22.6803 12.2787 23.7337 10.9987 23.7337ZM13.6654 6.33366H8.33203C7.78536 6.33366 7.33203 5.88033 7.33203 5.33366C7.33203 4.78699 7.78536 4.33366 8.33203 4.33366H13.6654C14.212 4.33366 14.6654 4.78699 14.6654 5.33366C14.6654 5.88033 14.212 6.33366 13.6654 6.33366Z" fill="#F5BF0B"/>
                                </svg>
                                <span>Mobile Apps</span>
                            </button>
                        </div>
                        <div className="filter_tabs" data-aos="fade-up" data-aos-delay={250}>
                            <div className="app_name active">Healthcare</div>
                            <div className="app_name">Fitness</div>
                            <div className="app_name">Home Care</div>
                            <div className="app_name">Education</div>
                            <div className="app_name">Dating App</div>
                            <div className="app_name">Hiring</div>
                            <div className="app_name">Social Media App</div>
                            <div className="app_name">Textile Garments</div>
                            <div className="app_name">Auto Mobile & GPS</div>
                        </div> */}
                        <div className="row">
                            {OurWorkJson &&
                                OurWorkJson.length > 0 &&
                                OurWorkJson.map((data, index) => (
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-sx-6">
                                        <Link to={`/work-details/${data.link_title}`} key={index}>
                                            <div className="work_box" data-aos="fade-right" data-aos-anchor-placement="top-bottom">
                                                <div className="wok__image__wrapper">
                                                    <div className="work_img">
                                                        <img src={`${process.env.PUBLIC_URL}${data.image}`} alt="" />
                                                    </div>
                                                </div>
                                                <div className="work_content">
                                                    <div>
                                                        <div className="box_title">{data.title}</div>
                                                        <div className="box_content">{data.description}</div>
                                                        <div className="box_badges">
                                                            {data.industry?.map((item, i) => (
                                                                <span className="badge_blue" key={i}>{item}</span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="box_action">
                                                        See Details <img src={Go} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
